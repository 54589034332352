import { type ISbStoryData, type StoryblokBridgeConfigV2, useStoryblokBridge } from '@storyblok/vue';
import { onMounted, type Ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

export default async function useAsyncCommon<T extends ISbStoryData = ISbStoryData>(
  story: Ref<T>,
  getStory: () => Promise<{ data: { story: T } }>,
  bridgeOptions: StoryblokBridgeConfigV2
) {
  const i18n = useI18n();

  onMounted(() => {
    if (story.value && story.value.id) {
      useStoryblokBridge(
        story.value.id,
        evStory => {
          story.value = evStory as T;
        },
        bridgeOptions
      );
    }
  });

  if (!story.value) {
    const { data } = await getStory();
    story.value = data.story;
  }

  // watch current locale & update data when it changes
  watch(
    () => i18n.locale.value,
    async () => {
      const { data } = await getStory();
      story.value = data.story;
    }
  );

  return story;
}
